import React from "react"
import styles from "./Footer.module.css"
import { 
  Grid,
  Segment,
  Icon
} from 'semantic-ui-react'

function Footer ( ) {

  return (
    <Segment vertical className={styles.bgImage} >
      <Grid container stackable verticalAlign='middle' className={styles.gridStyling} style={{maxWidth:"770px !important",paddingTop:"70px"}}>
        
        <Grid.Row  columns="3" className={styles.rowstyling} >
          <Grid.Column  className={styles.columnStyling}>
            <h4 className={styles.contact}> <Icon name='phone' left="true" size='small' />Phone number</h4>
            <p>801-736-4092</p>
          </Grid.Column>
          <Grid.Column className={styles.columnStyling} >
          <h4><Icon name='map marker alternate' left="true" size='small' />Located in</h4>
            <p>Salt Lake City</p>
          </Grid.Column>
          <Grid.Column className={styles.columnStyling} >
            <h4> <Icon name='mail' left="true" size='small' /> Email </h4>
            <p> greetings@polyhorse.com</p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="1" className={styles.rowstyling} >
          <Grid.Column className={styles.columnStyles}>
          <Icon name='twitter' left="true" size='big' className={styles.socials} />
          <Icon name='linkedin' left="true" size='big' className={styles.socials}/>
          <p className={styles.copyright} > © 2021 polyhorse LLC</p>
          </Grid.Column>
        </Grid.Row>

      </Grid>
    </Segment>
  )
}
export default Footer