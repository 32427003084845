import React from "react";
import CaseStudyCard from  "./CaseStudyCard/CaseStudyCard.js"
import Quote from "./Quote/Quote.js"
import Hero from "./Hero/Hero.js"
import CaseStudyHeader from "./CaseStudyHeader/CaseStudyHeader.js"
import Services from "./Services/Services"
import ContactForm from "./ContactForm/ContactForm"
import Footer from "../../Components/Footer/Footer.js";
import morphImg from "../../assets/images/caseStudyMorphLogo.png" 
import morphImgBg from "../../assets/images/caseStudyMorphBg.jpeg";
import RiotLogo from "../../assets/images/caseStudyRiotLogo.png"
import RiotBg from "../../assets/images/caseStudyRiotBg.jpeg"
import reignLogo from "../../assets/images/caseStudyReignLogo.png"
import reignBg from "../../assets/images/caseStudyReignBg.jpeg"
import ArtistLogo from "../../assets/images/caseStudyArtistToolsLogo.png"
import ArtistBg from "../../assets/images/caseStudyArtistToolsBg.jpeg"
import ReadyRoomLogo from "../../assets/images/caseStudyReadyRoomLogo.svg"
import ReadyRoomBg from "../../assets/images/caseStudyReadyRoomBg.jpeg"


function Home( ) {
    const CardsList = [ 
        { paragraph:"Create a sexy, interactive site that imparts a sense of environment, showcases  player data with generative graphics and an interactive 3D globe map displaying world-wide player activity. It had to be tuned well from the get-go, and would require backend scalability.", 
        logo:RiotLogo, background:RiotBg, linkToPage:"/Casestudy/riot" }, 
        
        {  paragraph: "Daz 3D launched the Morph brand with a mission to expand their 3D technology and content platform into the game and real time simulation markets. Given the technological requirements of real time 3D graphics, as the Morph team we needed to create a new ecosystem.",
         logo:morphImg, background:morphImgBg, linkToPage:"/Casestudy/morph3d" }, 

        { paragraph:"Jaybird, known for high-end Bluetooth sports headphones, wanted to branch out into fitness apps and hardware. They needed someone to help them create the mobile app, and supporting cloud storage for the fitness data. Additionally, a release of the prototype was due for CES 2014 to help get distribution contracts for store release June 2015. The success of the app and fitness band would be directly correlated to our ability to make a splash at CES.",
          logo:reignLogo, background:reignBg, linkToPage:"/Casestudy/reign" },

          { paragraph:"With 20,000 developers using their new interactive character platform (MCS), Morph3D had a new problem that needed to be solved: attracting top-tier game artists to produce and sell fresh content for ravenous game developers.",
          logo:ArtistLogo, background:ArtistBg, linkToPage:"/Casestudy/artistTools" }, 

          { paragraph:"Armed with a real-time character platform, Morph 3D was positioned to develop strategic partnerships and demonstrate value to potential investors. They required a showcase for their content and technology.",
          logo:ReadyRoomLogo, background:ReadyRoomBg, linkToPage:"/Casestudy/readyRoom" },

        ]




      const ReusableCards = CardsList.map( (item,index) => { console.log(index %2 ) 
        const IsEven = index%2
        return(
      <CaseStudyCard IsEven={IsEven} key={item.linkToPage} paragraph={item.paragraph} logo={item.logo} linkToPage={item.linkToPage} background={item.background}/>)}
          )
console.log(Text)


    return (
        <>
            <Hero /> 
            <Services/> 
            <Quote/> 
            <CaseStudyHeader/> 
            {ReusableCards}
            {/* <ContactForm/>  */}
            <Footer/>
        </>
    )

}

export default Home