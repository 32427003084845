import React  from "react"
import styles from "./CaseStudy.module.css"
import { Grid, Segment, Icon , header, GridRow, GridColumn, Embed, Image, Item} from 'semantic-ui-react'
import MorphHero from "../../assets/images/morphHero.jpeg";
import RiotHero from "../../assets/images/riotHero.jpg";
import Footer from "../../Components/Footer/Footer.js";
import ArticleSection from "./ArticleSection/ArticleSection.js";
import ReignHero from "../../assets/images/jaybird-reign-featured.jpeg";
import ArtistToolsHero from "../../assets/images/artist_tools_featured.jpeg";
import ReadyRoomHero from "../../assets/images/readyroom_featured.jpeg";

function CaseStudy (props) {
console.log (props)

const allPageData = {
  morph3d:{
    hero:MorphHero,
    title:'MORPH 3D MORPH CHARACTER SYSTEM',
    sections:[
      {
        subtitle:'CONUNDRUM',
        content:'Daz 3D launched the Morph brand with a mission to expand their 3D technology and content platform into the game and real time simulation markets. Given the technological requirements of real time 3D graphics, as the Morph team we needed to create a new ecosystem.',
        video:'O6Xo21L0ybE',
        image:MorphHero
      },
      {
        subtitle:'SCHEME',
         content:'Create a scalable and monetizable game engine character platform that could serve game developers and content creators',
        video:'O6Xo21L0ybE',
        image:MorphHero
      },
      {
        subtitle:'FRUITION',
         content:'The Morph Character System was released in the fall of 2015, it made a big splash on the Unity asset store and over the next year was downloaded about 20,000 times. The character system consists of a Unity plugin and specially processed 3D assets. The plugin allows developers to customize the appearance of a high quality 3D figure with over 300 blendshapes/morphs: fat to thin, young to old and many details in between. A large variety of auto-fit clothing can then be used to attire the customized figure. The completed character can then be used in the Unity game engine for games and presentations. Development of this system gave us an opportunity to dive deep in Unity 3D’s API and build a processing pipeline that converts external 3D content into Unity ready assets.',
       
      },
    ]
  },
  riot:{
    hero:RiotHero,
    title:'RIOT GAMES BEYOND THUNDERDOME',
    sections:[
      {
        subtitle:'CONUNDRUM',
        content:'Create a sexy, interactive site that imparts a sense of environment, showcases player data with generative graphics and an interactive 3D globe map displaying world-wide player activity. It had to be tuned well from the get-go, and would require backend scalability.',
       
      },
      {
        subtitle:'SCHEME',
         content:'Create an entertaining interactive website worthy of the attention brought on by millions of League of Legends fans.',
        image:RiotHero
      },
      {
        subtitle:'FRUITION',
         content:'The Thunderdome website launched on schedule to the fans of League of Legends, and successfully served over 150,000 users opening weekend.',
         image:RiotHero
      },
    ]
  },
  reign:{
    hero:ReignHero,
    title:'Reign data amf snkoksjko CHARACTER SYSTEM',
    sections:[
      {
        subtitle:'CONUNDRUM',
        content:'Jaybird, known for high-end Bluetooth sports headphones, wanted to branch out into fitness apps and hardware. They needed someone to help them create the mobile app, and supporting cloud storage for the fitness data. Additionally, a release of the prototype was due for CES 2014 to help get distribution contracts for store release June 2015. The success of the app and fitness band would be directly correlated to our ability to make a splash at CES.',
        video:'O6Xo21L0ybE',
        image:ReignHero
      },
      {
        subtitle:'SCHEME',
         content:'Create a high-end mobile app suitable for elite athletes, and a data storage service scalable for the expected growth of the first year.',
        video:'O6Xo21L0ybE',
        image:ReignHero
      },
      {
        subtitle:'FRUITION',
         content:'The Jaybird Reign fitness app and matching fitness band was released at the consumer electronics show (CES) in 2014 to rave reviews. It received the coveted “best of show” award by TechCrunch and Engadget. The success of the launch was a critical step in the product in Best Buy and The Apple store. It’s success eventually led to an acquisition by Logitech.Built in objective-C, this application features multithreaded data manipulation and custom interactive charts. A custom drawing library made in C was made specifically for the unique gauges and widgets featured in the users various dashboards.',
         image:ReignHero
      }, 
    ]
  },
  artistTools:{
    hero:ArtistToolsHero,
    title:'MORPH 3D ARTIST TOOLS',
    sections:[
      {
        subtitle:'CONUNDRUM',
        content:'With 20,000 developers using their new interactive character platform (MCS), Morph3D had a new problem that needed to be solved: attracting top-tier game artists to produce and sell fresh content for ravenous game developers.',
      
      },
      {
        subtitle:'SCHEME',
         content:'Enable game artists and content creators to make and sell MCS compatible game content.',
        video:'O6Xo21L0ybE',
        image:ArtistToolsHero
      },
      {
        subtitle:'FRUITION',
         content:'The Artist Tools application was delivered in fall of 2016, it provides artists the ability to import, organize and process content created in 3D applications like Maya, 3D Studio Max and Blender into MCS ready content. Artist Tools bridges the gap between user created content and the MCS ecosystem. Development of this application involved becoming fluent with AutoDesk’s filmbox (FBX) format, creating an auto-skinning algorithm and integrating Protocol Buffers with Unity based applications.',
        
      },
      
    ]
  },
  readyRoom:{
    hero:ReadyRoomHero,
    title:'MORPH 3D READY ROOM VR',
    sections:[
      {
        subtitle:'CONUNDRUM',
        content:'Armed with a real-time character platform, Morph 3D was positioned to develop strategic partnerships and demonstrate value to potential investors. They required a showcase for their content and technology.',
        video:'O6Xo21L0ybE',
        image:ReadyRoomHero
      },
      {
        subtitle:'SCHEME',
         content:'Create a Virtual Reality (VR) avatar builder, that can launch those avatars into other VR experiences like HIgh Fidelity and VR Chat. All of which would be unveiled at the Virtual Reality Developers Conference (VRDC) 2016.',
      },
      {
        subtitle:'FRUITION',
         content:'Ready Room, a VR based character creation tool (for the HTC Vive platform), was released in the fall of 2016 at VRDC. It was exhibited in a booth at the conference, and later in a custom-made installation space at Morph 3D’s offices. Early in 2017 Ready Room was made available for customer download and use.',
      },
      
    ]
  },
}

const pageDataToRender = allPageData[props.match.params.wallet]


const ReusableSections = pageDataToRender.sections.map (section=>(<ArticleSection key={section.subtitle} data={section}/>)

)


 return ( 
 <>
  <header >
   <Image src={pageDataToRender.hero} fluid/>
      </header> 
   
    
      <Grid  container className={styles.wrapping} >
        <Grid.Row>
          <Grid.Column>
            <h1 className={styles.h1Text}>{pageDataToRender.title}</h1>
            <hr className={styles.divider}></hr>
          </Grid.Column>
        </Grid.Row >
       
        
        {ReusableSections}
       
      </Grid>
     
      
      
   <Footer/> 
   </>
   
 )
}

export default CaseStudy