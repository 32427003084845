//import Navbar from "./Components/Navbar/Navbar.js";
// import Footer from "./Components/Footer/Footer.js"
 import CaseStudy from "./Pages/CaseStudy/CaseStudy.js"
import React from 'react'
import './App.css'
import './Fonts.css'
import Home  from "./Pages/Home/Home.js";
import {  Route, BrowserRouter as Router , Switch } from "react-router-dom";
import { Container } from 'semantic-ui-react'
import CaseStudyCard from './Pages/Home/CaseStudyCard/CaseStudyCard';

function App() {

 
  return (
    <Router>

<Container fluid >  
  
    
    {/* <footer/> */}
    {/* <CaseStudy/> */}
    
    
    </Container>
    <Switch>
    <Route path="/CaseStudy/:wallet" component={CaseStudy} />
    <Route path="/" component={Home } />
    </Switch>
    </Router>
   
  );
}


export default App;
