import React from "react";
import styles from "./CaseStudyCard.module.css"
import { Grid, Segment } from 'semantic-ui-react'

function CaseStudyCard(props) {
  console.log (props)

  const buttonWasClicked = () =>{
    console.log ("piss")
    window.location.href= props.linkToPage
  }

return (

  <Segment style={{ padding: '3em 0em' ,backgroundImage:`url(${props.background})` }} vertical className={props.IsEven? styles.bgImage : styles.bgImageAttachment}  >
  <Grid container stackable verticalAlign='middle'className={styles.rowstyling}>
  <Grid.Row columns='2'>
      <Grid.Column className={styles.column1}>
     <img className={styles.logoImage} src={props.logo} alt="morphImage"   />
      
      </Grid.Column>
      <Grid.Column>
  <p className={styles.text}>
    {props.paragraph} 
  </p> 
  <button className={styles.buttonStyle }  onClick= {buttonWasClicked}>
    View details
  </button>
      
      </Grid.Column>
  </Grid.Row>
  </Grid>
  </Segment>

)


}
export default CaseStudyCard