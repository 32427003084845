import React from "react";
import styles from "./CaseStudyHeader.module.css"
import { Grid, Segment} from 'semantic-ui-react'
  
function CaseStudyHeader (){
 
 


return (
  <Segment style={{ padding: '8em 0em' }} vertical className={styles.bgImage} >
  <Grid container stackable verticalAlign='middle'>
  <Grid.Row columns="1" className={styles.wrapper} >
  <Grid.Column>
  <h2 className={styles.header}>Work</h2>
  <hr className={styles.divider}/>
  <p className={styles.text}> Below are some examples of our recent work.</p>
  </Grid.Column>
</Grid.Row>
</Grid>
</Segment>
)


}
export default CaseStudyHeader