import React from "react";
import styles from "./Services.module.css"
import { Grid, Segment, Icon} from 'semantic-ui-react'



function Services( ) {
  
return (
  <Segment className={styles.wrapper}  vertical  >
  <Grid container stackable verticalAlign='top'>
    <Grid.Row columns="1">  
    <Grid.Column >
    <h1 className={styles.header}>THIS IS WHAT WE DO</h1>
    <hr className={styles.divider}/>
    </Grid.Column>
    </Grid.Row>

  <Grid.Row columns='4'className={styles.rowstyling} >
      <Grid.Column  >
      <Icon name='code' left="true" size='huge' className={styles.Icon} />
        <h3 className={styles.products}>WEB APPLICATIONS</h3>
         <p className={styles.paragraph}>From front-end, user experiences to microservices, we've made sites serving 80+ million users, in 24 languages.</p>
      </Grid.Column>
      <Grid.Column>
      <Icon name='shop' left="true" size='huge' />
         <h3 className={styles.products}>ECommerce & shopping</h3>
         <p className={styles.paragraph}>Available for all your shopify theme building, custom app making, and conversion rate optimization needs.</p>
      </Grid.Column>
      <Grid.Column>
      <Icon name='microchip' left="true" size='huge' className={styles.Icon} />
      <h3 className={styles.products}> IOT & DEVICE PROTOTYPES </h3>
         <p className={styles.paragraph}>Years of experience 
           to build all parts of a project including the API, client app, device firmware, and circuit design.</p>
    
      </Grid.Column>
      <Grid.Column  >
      <Icon name='tablet alternate' left="true" size='huge' className={styles.Icon}/>
      <h3 className={styles.products}> KIOSK & INSTALLATIONS</h3>
         <p className={styles.paragraph}>Previous projects include drawbots for art festivals,
            bullet-time rigs, kiosks and virtually reality experiences. </p>
    
      </Grid.Column>
  </Grid.Row>
  </Grid>
</Segment>
)


}
export default Services