import React from "react";
import styles from "./Hero.module.css"
import { Grid, Segment } from 'semantic-ui-react'
import logo from "../../../assets/images/polyhorseLogo.svg"


function Home (){
 
 


return ( 
<Segment vertical className={styles.bgImage} >
<Grid container stackable verticalAlign='middle'>
  <Grid.Row columns="1" className={styles.heroBg}>
  <Grid.Column>

<img className={styles.logo} src={logo} alt="logo"  />
<h1 className={styles.name}>Polyhorse</h1>
<hr className={styles.divider}/>
<p className={styles.slogan}>We write code for those in need.</p>


  </Grid.Column>
</Grid.Row>
</Grid>
</Segment>
)


}
export default Home