import React from "react";
import styles from "./Quote.module.css"
import { 
  Grid,
  Segment,
} from 'semantic-ui-react'

function Quote( ) {


  return (
    <Segment vertical className={styles.bgImage} >
      <Grid container stackable verticalAlign='middle' className={styles.gridStyling} style={{maxWidth:"770px !important"}}>
          <Grid.Row  columns="1"   >
            <Grid.Column className={styles.columnStyling}>
              <h1 className={styles.quoteText}>We are stuck with technology when what we really want is just stuff that works.</h1>
              <h2 className={styles.quoteAuthor}> - Douglas Adams</h2>
            </Grid.Column>
          </Grid.Row>
      </Grid>
    </Segment>
  )

}
export default Quote