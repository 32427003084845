import React  from "react"
import styles from "./ArticleSection.module.css";
import MorphHero from "../../../assets/images/morphHero.jpeg";
import JayBirdHero from "../../../assets/images/jaybird-reign-featured.jpeg";
import ReadyRoomHero from "../../../assets/images/readyroom_featured.jpeg";
import RiotHero from "../../../assets/images/thunderdome_featured_3.jpeg";
import ArtistToolsHero from "../../../assets/images/artist_tools_featured.jpeg";
import Footer from "../../../Components/Footer/Footer.js";
import { Grid,
  Image,
Embed,
 } from "semantic-ui-react";

function ArticleSection (props) {
  console.log (props)  

const MediaLogic = (section) =>{
  console.log ("smd")
if (section.video){
  return (<Embed
    width='800'
    id={section.video}
    placeholder={section.image}
    source='youtube'
    />)

}
else if (section.image) {
return ( <Image src={section.image}  />)
}

}




return (


  <Grid.Row  className={styles.RowStyles}>
  <Grid.Column className={styles.ColumnStyling}>
    <h3 className={styles.h3styling}>{props.data.subtitle}</h3>
    <p className={styles.ParagraphText}>{props.data.content} </p>
{MediaLogic(props.data)}
</Grid.Column>
</Grid.Row>
)
}

export default ArticleSection










